import styled, { css } from "styled-components";
import { color, fonts } from "../../styles";
import { FC } from "react";
import { mobile, tablet } from "components/templates/Breakpoints";
import { convertNewlinesToBreaks } from "utils/string";

export interface QuoteSlideProps {
  quote: string;
  bigImg?: string;
  source?: string;
  logo?: string;
  logoWidth?: string;
  hasQuoteMarks?: boolean;
}

export const QuoteSlide: FC<QuoteSlideProps> = ({
  quote,
  bigImg,
  source,
  logo,
  logoWidth,
  hasQuoteMarks = true,
}) => {
  if (bigImg) {
    return (
      <QuoteContainer $hasbigImg={!!bigImg}>
        <Wrapper>
          <Headshot src={bigImg} alt={source} />
          <div>
            <Quote $hasbigImg={!!bigImg} hasQuoteMarks={hasQuoteMarks}>
              {convertNewlinesToBreaks(quote)}
            </Quote>
            {source && <Source>{source}</Source>}
          </div>
        </Wrapper>
      </QuoteContainer>
    );
  }

  return (
    <QuoteContainer $hasLogo={!!logo}>
      <Logo src={logo} alt={logo} $width={logoWidth} />
      <Quote $hasHighWordCount={quote.length > 200} hasQuoteMarks={hasQuoteMarks}>
        {convertNewlinesToBreaks(quote)}
      </Quote>
    </QuoteContainer>
  );
};

const Wrapper = styled.div`
  margin: 3rem 0 0 1rem;
  display: flex;
  gap: 3rem;
  align-items: center;

  ${tablet} {
    gap: 1rem;
  }

  ${mobile} {
    margin: 3rem 2rem;
  }
`;

const Logo = styled.img<{
  $width?: string;
}>`
  margin-bottom: 1rem;
  width: ${(p) => (p.$width ? p.$width : "auto")};
`;

const Headshot = styled.img`
  width: 40%;

  ${mobile} {
    display: none;
  }
`;

const QuoteContainer = styled.div<{ $hasbigImg?: boolean; $hasLogo?: boolean }>`
  margin: 0 auto;
  display: flex;
  padding-top: 2rem;

  ${(props) =>
    props.$hasLogo &&
    css`
      flex-direction: column;
      align-items: center;
    `}

  ${tablet} {
    padding-top: ${(p) => (p.$hasbigImg ? "0" : "2rem")};
  }
`;

const Quote = styled.q<{
  $hasbigImg?: boolean;
  $hasHighWordCount?: boolean;
  hasQuoteMarks: boolean;
}>`
  display: block;
  font-family: ${fonts.heading};
  color: ${color.white};
  padding: ${(p) => (p.$hasbigImg ? "0 1.5rem 0 2.5rem" : "2rem")};
  ${(p) => (p.hasQuoteMarks ? "" : "quotes: none;")}

  ${(p) =>
    p.$hasbigImg
      ? `
          font-size: 2.8rem;
          line-height: 3.25rem;
          text-align: left;
          max-width: 36rem;

          ${tablet} {
            font-size: 2.15rem;
            line-height: 2.75rem;
            padding-left: 1rem};
          }
        `
      : `
        font-size: ${p.$hasHighWordCount ? "2.5rem" : "3.5rem"};
        line-height: ${p.$hasHighWordCount ? "3.25rem" : "4rem"};
        text-align: center;
        max-width: 66rem;

        ${tablet} {
          font-size: ${p.$hasHighWordCount ? "2rem" : "2.8rem"};
          line-height: ${p.$hasHighWordCount ? "2.75rem" : "4rem"};
        }
        `}

  ${mobile} {
    font-size: ${(p) => (p.$hasHighWordCount ? "1.5rem" : "2.3rem")};
    line-height: ${(p) => (p.$hasHighWordCount ? "2.25rem" : "3rem")};
    text-align: center;
  }
`;

const Source = styled.p`
  color: ${color.white};
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 2rem;
  letter-spacing: 0.075rem;
  text-align: left;
  padding: 1.5rem 2rem 0 2.5rem;

  ${tablet} {
    padding-left: 1rem;
  }

  ${mobile} {
    text-align: center;
  }
`;
